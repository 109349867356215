
@import "_bootstrap.4.6.2.scss";

$kendo-button-bg: $input-bg;
$kendo-button-border: $input-border-color;
$kendo-button-hover-border: $kendo-button-border;
$kendo-picker-hover-bg: $kendo-button-bg;
$kendo-button-active-bg: $kendo-button-bg;
$kendo-button-focus-border: $kendo-button-border;

$kendo-list-item-selected-text: white;

// Kendo Core S
@import "../node_modules/@progress/kendo-theme-bootstrap/dist/all.scss";

