
$tags-review-colors: (
    "features":    $primary,
    "reviews": $berry,
    "first": $warning
);

@each $color, $value in $tags-review-colors {
    .tag-#{$color} {
        @if $color == first {
            background-color: lighten($value, 30%);
            color: darken($value, 20%);
        } @else {
            background-color: lighten($value, 50%);
            color: darken($value, 10%);
        }
        border-radius: $badge-border-radius;
        //padding-top: map-get($spacers, 1 ) * .5;
        //padding-bottom: map-get($spacers, 1 ) * .5;
        padding-left: map-get($spacers, 1 ) * 1.25;
        padding-right: map-get($spacers, 1 ) * 1.25;
    }
}

.fish-badge {
    position: absolute;
    color: $secondary;
    bottom: map-get($spacers,1);
    span:not(:last-child) {
        &::after {
            content: "\2022";
            margin-left: map-get($spacers, 1);
            margin-right: map-get($spacers, 1);
        }
    }
}