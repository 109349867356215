
$btn-colors: (
    "berry":    $berry
);

.btn-cart {
  $cartcolor: lighten($dark,10%);
  @include button-variant(
      $cartcolor,
      $cartcolor,
      $hover-background: shade-color($cartcolor, $btn-hover-bg-shade-amount),
      $hover-border: shade-color($cartcolor, $btn-hover-border-shade-amount),
      $active-background: shade-color($cartcolor, $btn-active-bg-shade-amount),
      $active-border: shade-color($cartcolor, $btn-active-border-shade-amount)
    );
}