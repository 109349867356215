.admin-top {
    padding-right: map-get($spacers, 3);
    padding-left: map-get($spacers, 3);
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
}

.page-edit-form {
    background-color: #ccc;
    border-top: 2px solid #aaa;
    width: 100%;
    position: absolute;
    top: 50px;
    padding: 30px;
    font-family: "Arial", sans-serif;
}

#page-edit-form input, #page-edit-form textarea {
    width: 100%;
    background-color: white;
}