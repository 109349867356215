.product-description {
    margin-top:1rem;
}

.product-title {
    font-size: 2rem;
}

.product-card {
    .img-holder {
        height: 150px;
        img {
            max-width: 100%;
    max-height: 100%;
        }
    }
    .card-footer {
        .btn:hover {
            background-color: $berry;
            color: white;
        }
    }
}

.slide-show-img {
    img {
        width: 100%;
    }
}

.thumb-wrap {
    .slide-thumb {
        width:50px;
        height: 50px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.card-img-top {
    width: auto;
    margin: 1rem;
    max-height: 100px;
    max-width: 180px;
    margin-bottom: 0;
}

.list-group-pills {
    padding: map-get($spacers, 3);
    @include border-radius($border-radius);
    background-color: $card-bg;
    border: $card-border-width solid $card-border-color;
    li {
        list-style: none;
        padding: map-get($spacers, 1) map-get($spacers, 3);
        &:nth-child(odd) {
            background-color: darken($light, 6%);
            @include border-radius($border-radius);
        }
    }
}


.specs-list {
    display: flex;
    flex-direction: column;
    
    // No need to set list-style: none; since .list-group-item is block level
    padding-left: 0; // reset padding because ul and ol
    margin-bottom: 0;
    @include border-radius($list-group-border-radius);
    li {
        position: relative;
        display: flex;
        padding: $list-group-item-padding-y $list-group-item-padding-x;
        color: $list-group-color;
        text-decoration: if($link-decoration == none, null, none);
        background-color: $list-group-bg;
        border: $list-group-border-width solid $list-group-border-color;
      
        &:first-child {
          @include border-top-radius(inherit);
        }
      
        &:last-child {
          @include border-bottom-radius(inherit);
        }
      
        &.disabled,
        &:disabled {
          color: $list-group-disabled-color;
          pointer-events: none;
          background-color: $list-group-disabled-bg;
        }
      
        // Include both here for `<a>`s and `<button>`s
        &.active {
          z-index: 2; // Place active items above their siblings for proper border styling
          color: $list-group-active-color;
          background-color: $list-group-active-bg;
          border-color: $list-group-active-border-color;
        }
      
        // stylelint-disable-next-line scss/selector-no-redundant-nesting-selector
        & + li {
          border-top-width: 0;
      
          &.active {
            margin-top: calc(-1 * $list-group-border-width); // stylelint-disable-line function-disallowed-list
            border-top-width: $list-group-border-width;
          }
        }
        span {
            flex: 1;
            &:first-child {
                color: $text-muted;
                text-transform: uppercase;
            }
        }
    }
}
