// Google Fonts -- IBM Plex Serif, EB Garamond
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&family=IBM+Plex+Serif:wght@400;700&family=IBM+Plex+Sans+Condensed:ital,wght@0,400;0,700;1,400;1,700&family=IBM+Plex+Sans:ital,wght@0,400;0,700;1,400&display=swap');

@import "../../bootstrap4/scss/kendo-ui.scss";

@import "_bootstrap.5.3.1.scss";

@import "icatchfish/_admin-panel";
@import "icatchfish/_icf-main";
@import "icatchfish/icf-buttons";
@import "icatchfish/icf-tags-review";
@import "icatchfish/icf-products";

