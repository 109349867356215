/*body {
    font-family: "Catamaran", sans-serif;
    padding: 0;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    font-family: 'Lora', sans-serif;
    font-weight: bold;
}*/

.move-invalid {
    margin-top: -30px;
}

.was-validated .form-control:valid {
    border-color: #ced4da;
}

.prod-price, #del-btn {
    display: inline-block;
}

.amazon-checkout {
    display: none;
}

.amazon-checkout .row {
    background-color: #f2f2f2;
    border-top: 1px solid #ddd;
    margin-right: 0;
    margin-left: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.order-total {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 1.15rem;
    font-weight: bold;
    padding-bottom:1rem;
}

.order-total span {
    display: inline-block;
    padding-left: 15px;
}

.ui-datepicker {
    z-index: 4000 !important;
}

.modal img {
    width: 100%;
}

@media (max-width: 991px) {
    .jumbo-img {
        padding-bottom: 2rem;
    }
}

.logo {
    height: 35px;
    vertical-align: inherit;
    margin-top: -(map-get($spacers, 2) + .15);
    margin-bottom: -(map-get($spacers, 3) - .25);
}

body, .footer { /* Body bg-color and footer-bg color have to match */
    background-color: $body-bg;
}
.footer {
    padding: 2rem 0;
}
.mid-section {
    background-color: lighten($gray-200, 2%);
    min-height: 600px;
    padding-bottom: 2rem;
    border-bottom: solid $border-color 1px;
}
.jumbo-img img {
    width: 100%;
}
.jumbotron small {
    font-size: 1rem;
}
.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.btn-primary {
    color: #fff;
    background-color: #828228;
    border-color: #8c8c2b;
}
.well {
    background-color: #828228;
    margin-top: 3rem;
    margin-bottom: -2rem;
    color: #fff;
}

.reading-page {
    p {
        margin-bottom: map-get($spacers, 4);
    }
}

.well .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.categories-card {
    text-align: center;
}

@media (max-width: 575px) {
    .list-group h5 {
        padding-top: 1rem;
        padding-bottom: .75rem;
    }
}

.prod-tab {
    position: relative;
}

.prod-img {
    max-width: 60px;
    text-align: center;
    img {
        max-height: 100%;
        max-width: 100%;
        display: inline-block;
    }
}

.cat_img {
    width: 100%;
}

.display-img img {
    width: 100%;
    margin-bottom: 2rem;
}

.review-overview strong {
    display: block;
    margin-top: 1rem;
}
.review-overview strong:first-child {
    margin-top:0;
}
.review-overview {
    margin-bottom: 2rem;
}

.article-list {
    .post-img {
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

#fish_desc {
    height: 65px;
}

#mc_embed_signup .button {
    background-color: #828228 !important;
    width: 88% !important;
    margin: 0 15px 15px 15px !important;
}

#mc_embed_signup .email {
    margin: 0 15px 15px 15px !important;
    width: 88% !important;
    padding: 6px 15px !important;
}

#mc_embed_signup .clear {
    display: grid !important;
}

#mc_embed_signup {
    background-color: #555;
    border-radius: 4px;
}

#mc_embed_signup form {
    padding:0 !important;
}

#mc_embed_signup label {
    color: white;
    border-bottom: 1px solid #777;
    padding: 15px;
}

.water-image {
    width: 100%;
}

.catch-card {
    margin-bottom: 2rem;
}
.catch-card > .card-header {
    font-size: 1.15rem;
    font-weight: bold;
    background-color: #555;
    color: white;
    font-family: 'Lora', sans-serif;
}

.fish-type {
    font-weight: bold;
}

.fish-desc {
    margin: 5px 0;
}

.fish-text {

}

.fish-pic {
    margin-bottom:10px;
    min-width: 200px;
}

.fishes-grid {
    margin-bottom: 2rem;
}

.fishes-grid.lit img {
    box-shadow: 2px 2px 5px #bbb;
}

.fish-pic img, .fishes-grid .grid-pic img {
    width: 100%;
}

.fishes-grid > div {
    display: inline-block;
    vertical-align: top;
}

.fishes-grid .grid-pic {
    width:33%;
    margin-right:15px;
}

.fishes-grid .grid-desc, .fishes-grid .grid-options {
    width:64%;
}

#mapid {
    height: 100%;
    min-height: 400px;
}
.fish-report .catch-section {
    margin-bottom: 2rem;
}
.fish-report label {
    margin-bottom: .15rem;
    font-size: .91rem;
}

.water-stats .list-group-item span::after {
    content: ", ";
}
.water-stats .list-group-item span {
    font-weight: bold;
}

.water-stats .stats-title {
    font-size: .875rem;
}

.water-stats .list-group-item span:last-child::after {
    content: "";
}

#photomodal img {
    width: 100%;
}

#photomodal .modal-header {
    padding: 0;

}

#photomodal .modal-header .close {
    border-radius: 20px;
    padding: 1px 8px 3px 8px;
    background-color: white;
    border: 1px solid #ccc;
    opacity: 1;
}

#photomodal .modal-header {
    padding-top: 0;
    border-bottom: none;
}

#fish_type_listbox {
    .k-list-item-text {
        &::before {
            content: none;
        }
        .fish-species-menu {
            .species-img img {
                width: 120px;
            }
        }
    }
}

.k-dropdownlist {
    .k-input-value-text {
        &::before {
            content: none;
        }
        .fish-species-menu {
            .species-img img {
                width: 70px;
            }
        }
    }
}

@media (max-width: 768px) {
    .water-stats {
        margin-top: 2rem;
    }
    .fishes-grid .grid-pic {
        width: 100%;
        margin-right: 0;
    }
    .fishes-grid .grid-desc {
        margin-top: 1rem;
    }

}

.water-list div.list-group-item {
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
}
.water-list div.list-group-item a.water-link {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #333;
    text-decoration: none;
    display: inline-block;
    width: 70%;
    cursor:pointer;
}

.water-list div.list-group-item .btn {
    position: absolute;
    right: 1.25rem;
    top: .5rem;
}

#reportmap {
    height: 400px;
}

@include media-breakpoint-down(lg) { 
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x);
    }
}

@include media-breakpoint-down(sm) { 
    .article-list {
        .post-img {
            height: 60%;
        }
    }
}

@include media-breakpoint-down(xs) { 
    .navbar-brand {
        margin-right: 0;
    }
}